import { getAccessToken, useLogin, usePrivy } from "@privy-io/react-auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../App.css";
import { useGlobalContext } from "../context";
import axios from "axios";

function useCustomLogin() {
  const { ready, authenticated, logout } = usePrivy();
  const { setIsAuthenticated, setErrorMessage } = useGlobalContext();
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const urlEndpoint = process.env.REACT_APP_FISH_BE_ENDPOINT;
  const next = search.get("next") ? window.atob(search.get("next")) : null;

  const { login } = useLogin({
    onComplete: async (
      user,
      isNewUser,
      wasAlreadyAuthenticated,
      loginMethod,
      linkedAccount
    ) => {
      console.log(
        user,
        isNewUser,
        wasAlreadyAuthenticated,
        loginMethod,
        linkedAccount
      );
      const privyToken = await getPrivyAccessToken(); // Lấy access token từ user object
      let url_login = urlEndpoint + "/auth/login";
      try {
        const { data } = await axios.post(
          url_login,
          JSON.stringify({ walletAddress: user?.wallet?.address }),
          {
            headers: {
              "Content-Type": "application/json",
              "x-privy-token": privyToken,
            },
          }
        );

        console.log(data.tokens.access.token);
        localStorage.setItem("fishAuth", data.tokens.access.token);
        localStorage.setItem("userId", data.user.id);
        console.log("Logged in FISH_BE");
        setIsAuthenticated(authenticated);
        localStorage.setItem("isAuthenticated", true);
        localStorage.setItem("fish_be_endpoint", urlEndpoint);
        navigate(next ?? "/");
      } catch (error) {
        logout();
        setIsAuthenticated(false);
        localStorage.removeItem("fishAuth");
        localStorage.removeItem("userId");
        localStorage.removeItem("isAuthenticated");
        setErrorMessage(error.response.data.message);
        navigate("/login");
      }
    },
  });

  const getPrivyAccessToken = async () => {
    const accessToken = await getAccessToken();
    console.log(accessToken);
    return accessToken;
  };

  return { login, ready };
}

export default useCustomLogin;
