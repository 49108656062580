import { PrivyProvider } from "@privy-io/react-auth";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { GlobalProvider } from "./context";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { base , baseSepolia } from 'viem/chains';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <PrivyProvider
      appId={process.env.REACT_APP_PRIVY_APP_ID}
      config={{
        appearance: {
          walletList: ["metamask"],
        },
        defaultChain: baseSepolia,
        embeddedWallets: {
          createOnLogin: "all-users",
          noPromptOnSignature: true,
          waitForTransactionConfirmation: true,
          priceDisplay: {
            primary: 'native-token', // Defaults to 'fiat-currency'
            secondary: null, // Defaults to 'native-token'
          },
        },
      }}
    >
      <GlobalProvider>
        <App />
      </GlobalProvider>
    </PrivyProvider>
  </React.StrictMode>
);

reportWebVitals();
