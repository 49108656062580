import React, { useEffect, useState, useRef } from "react";
import useCustomLogout from "./hook/useLogout";
import usePurchaseGold from "./hook/usePurchaseGold";
import useDeposit from "./hook/useDeposit";
import { usePrivy } from "@privy-io/react-auth";

const GameComponent = () => {
  const { customLogout } = useCustomLogout();
  const { purchaseGold } = usePurchaseGold();
  const { handleFund } = useDeposit();
  const { exportWallet } = usePrivy();

  const [containerStyle, setContainerStyle] = useState({
    height: "100dvh",
    width: "100vw",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  });

  const iframeRef = useRef(null);
  const [gameState, setGameState] = useState(() => {
    const savedState = localStorage.getItem("cocosGameState");
    return savedState ? JSON.parse(savedState) : null;
  });

  useEffect(() => {
    const handleMessage = (event) => {
      console.log("[React-handleMessage]", event);
      console.log(event.data.type);
      console.log(event.data);
      if (event.source === iframeRef.current?.contentWindow) {
        console.log("Nhận được sự kiện từ Cocos:", event.data);
        localStorage.setItem("cocosGameState", JSON.stringify(event.data));
        setGameState(event.data);
        if (event.data.type === "buttonLogOutClicked") {
          cocosLogoutBtnClick(event.data.data);
        } else if (event.data.type === "buttonBuyCoinClicked") {
          cocosExchangeGoldBtnClick(event.data.data);
        } else if (event.data.type === "buttonDepositClicked") {
          cocosDepositBtnClick(event.data.message);
        } else if (event.data.type === "buttonExportWalletClicked") {
          cocosExportWalletBtnClick(event.data.data);
        } else {
          const authToken = localStorage.getItem("fishAuth");
          const userId = localStorage.getItem("userId");
          const beAddress = localStorage.getItem("fish_be_endpoint");
          iframeRef.current.contentWindow.postMessage(
            { authToken: authToken, userId: userId, beAddress: beAddress },
            "*"
          );
          console.log("[React-postMessage] token: " + authToken);
        }
      }
    };

    window.addEventListener("message", handleMessage);

    // Gửi trạng thái đã lưu vào game sau khi iframe đã load
    const onIframeLoad = () => {
      if (gameState && iframeRef.current) {
        iframeRef.current.contentWindow.postMessage(
          { type: "RESTORE_STATE", state: gameState },
          "*"
        );
      }
    };

    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener("load", onIframeLoad);
    }

    return () => {
      window.removeEventListener("message", handleMessage);
      if (iframe) {
        iframe.removeEventListener("load", onIframeLoad);
      }
    };
  }, [gameState]);

  const cocosLogoutBtnClick = (data) => {
    console.log("Button in Cocos was clicked!", data);
    customLogout();
  };

  const cocosExchangeGoldBtnClick = (data) => {
    console.log("Button in Cocos was clicked!", data);
    purchaseGold();
  };

  const cocosDepositBtnClick = (amount) => {
    console.log("Button in Cocos was clicked!", amount);
    handleFund(amount);
  };

  const cocosExportWalletBtnClick = (data) => {
    console.log("Button in Cocos was clicked!", data);
    exportWallet();
  };

  return (
    <div style={containerStyle}>
      <iframe
        ref={iframeRef}
        src="/cocos/index.html"
        title="Cocos Game"
        style={{ width: "100%", height: "100%", border: "none" }}
      />
    </div>
  );
};

export default GameComponent;
