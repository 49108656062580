import React, { useContext, useState } from "react";

const GlobalContext = React.createContext({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  errorMessage: "",
  setErrorMessage: () => {},
});

export const GlobalProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <GlobalContext.Provider
      value={{
        isAuthenticated,
        errorMessage,
        setIsAuthenticated,
        setErrorMessage,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
