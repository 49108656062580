import { usePrivy, useWallets, getEmbeddedConnectedWallet } from "@privy-io/react-auth";
import { ethers } from "ethers";
import "../App.css";

function usePurchaseGold() {
  const urlEndpoint = process.env.REACT_APP_FISH_BE_ENDPOINT;
  const { sendTransaction } = usePrivy();
  const { wallets } = useWallets();
  const chainId = process.env.REACT_APP_BASE_CHAIN_ID;
  const ownerWallet = process.env.REACT_APP_OWNER_WALLET;
  const wallet = getEmbeddedConnectedWallet(wallets);

  const purchaseGold = async () => {
    try {
      // await wallet.switchChain(chainId);
      // console.log(wallet.chainId);
      const ethAmount = "0.001";
      const weiValue = ethers.utils.parseEther(ethAmount);
      const hexWeiValue = ethers.utils.hexlify(weiValue);
      const transactionRequest = {
        to: ownerWallet,
        value: hexWeiValue,
      };

      const txUiConfig = {
        header: `Send Transaction`,
        description: `Send ${ethAmount} ETH to owner`,
        buttonText: `Send`
      };

      const txReceipt = await sendTransaction(transactionRequest, txUiConfig);

      if (txReceipt.status === 1) {
        sendTransactionHashToBackend(txReceipt.transactionHash);
      } else {
        console.error('Transaction failed:', txReceipt);
      }
    } catch (error) {
      console.error('Lỗi khi gửi transaction:', error);
    }
  };

  const sendTransactionHashToBackend = async (txHash) => {
    try {
      const response = await fetch( urlEndpoint + '/trading/purchaseGold', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('fishAuth')}`, // Gửi JWT token trong header
        },
        body: JSON.stringify({ transactionHash: txHash }),
      });
      const data = await response.json();
      console.log('Transaction Info:', data);
    } catch (error) {
      console.error('Lỗi khi gửi transactionHash về backend:', error);
    }
  };

  return { purchaseGold };
}

export default usePurchaseGold;
