import config from "../config";
import Game from "../pages/Game";
import ReferenceCode from "../pages/ReferenceCode";

//Public routes
const publicRoutes = [
  { path: config.routes.ref, component: ReferenceCode },
  { path: config.routes.game, component: Game },
];

export { publicRoutes };
