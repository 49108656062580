import { usePrivy } from "@privy-io/react-auth";
import { useNavigate } from "react-router-dom";
import "../App.css";
import { useGlobalContext } from "../context";

function useCustomLogout() {
  const { logout } = usePrivy();
  const { setIsAuthenticated } = useGlobalContext();
  const navigate = useNavigate();

  const customLogout = () => {
    logout();
    setIsAuthenticated(false);
    localStorage.removeItem("fishAuth");
    localStorage.removeItem("userId");
    localStorage.removeItem("isAuthenticated");
    navigate("/login");
  };

  return { customLogout };
}

export default useCustomLogout;
