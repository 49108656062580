import React from "react";
import "../App.css";
import GameComponent from "../GameComponent";
import { usePrivy } from "@privy-io/react-auth";
import useCustomLogout from '../hook/useLogout';

function Game() {
  const { authenticated } = usePrivy();
  const { customLogout } = useCustomLogout();
  return authenticated ? (
    <div className="Game-container">
      {/* <button onClick={customLogout} className="Logout-button">
        Log Out
      </button> */}
      {
        <div>
          <GameComponent/> {/*the game */}
        </div>
      }
    </div>
  ) : (
    <></>
  )
}

export default Game;
