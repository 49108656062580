import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import OTPInput from "react-otp-input";
import { useSearchParams } from "react-router-dom";
import "../App.css";
import referenceCodeSuccess from "../verifySuccess.png";

function ReferenceCode() {
  const [search, setSearchParams] = useSearchParams();
  const [isVerifySuccess, setIsVerifySuccess] = useState(false);
  const [error, setError] = useState("");
  const urlEndpoint = process.env.REACT_APP_FISH_BE_ENDPOINT;
  const codeQuery = search.get("code") || "";

  useEffect(() => {
    if (codeQuery && codeQuery.length === 6) {
      handleSubmit();
    }
  }, [codeQuery]);

  const handleSubmit = useCallback(async () => {
    let url_login = urlEndpoint + `/reference-code/verify?code=${codeQuery}`;

    try {
      const response = await fetch(url_login, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("fishAuth"),
        },
      });

      if (!response.ok) {
        setIsVerifySuccess(false);
        setError("An error occurred while verifying the code");
      } else {
        const data = await response.json();
        console.log("success", data);
        setIsVerifySuccess(!isVerifySuccess);
      }
    } catch {}
  }, [codeQuery]);

  return (
    <div className="bg-reference-code">
      <Container className="m-auto d-flex justify-content-center">
        {isVerifySuccess ? (
          <div className="d-flex align-items-center gap-4">
            <img
              style={{
                width: "140px",
                height: "140px",
                objectFit: "cover",
              }}
              src={referenceCodeSuccess}
              alt=""
            />
            <Form.Text
              style={{ color: "green", fontWeight: 500, fontSize: "22px" }}
            >
              Rewards have been sent to your inventory
            </Form.Text>
          </div>
        ) : (
          <Row>
            <Col className="d-flex flex-column align-items-center gap-4">
              <h3 className="mb-4 text-primary dynamic-font-size">
                Reference Code
              </h3>
              <Form
                className="d-flex flex-column gap-3 align-items-center"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <Form.Group>
                  <OTPInput
                    shouldAutoFocus={true}
                    onChange={(e) => {
                      setError("");
                      setSearchParams({ code: e });
                    }}
                    value={codeQuery}
                    containerStyle={containerStyle()}
                    inputStyle={inputStyle()}
                    numInputs={6}
                    inputType="text"
                    renderInput={(props) => (
                      <input
                        {...props}
                        type="tel"
                        className="pin-input"
                        autoComplete="new-password"
                      />
                    )}
                    onPaste={(e) =>
                      setSearchParams({
                        code: e.clipboardData
                          .getData("text")
                          .replace(/\D/g, "")
                          .slice(0, 6),
                      })
                    }
                  />
                  {!!error && (
                    <Form.Text style={{ color: "red" }}>{error}</Form.Text>
                  )}
                </Form.Group>
              </Form>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default ReferenceCode;

export const containerStyle = () => ({
  width: "auto",
  height: "50px",
  padding: "0 12px",
  fontSize: "32px",
  letterSpacing: "14.4px",
  fontWeight: "500",
  lineHeight: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid ",
  borderRadius: "8px",
  transition: "color 0.3s",
  backgroundColor: "rgb(251, 243, 230)",
  verticalAlign: "middle",
});

export const inputStyle = () => ({
  border: "none",
  outline: "none",
  fontSize: "32px",
  fontWeight: "600",
  textAlign: "center",
  height: "100%",
  flex: 1,
  width: "42px",
  margin: "0 2px",
  backgroundColor: "rgb(251, 243, 230)",
  verticalAlign: "middle",
  display: "flex",
  alignItems: "center",
});
