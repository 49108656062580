import "../App.css";
import { useState } from 'react';
import { useWallets, getEmbeddedConnectedWallet } from "@privy-io/react-auth";
import { baseSepolia } from "viem/chains"

function useDeposit() {
  const { wallets } = useWallets();
  const wallet = getEmbeddedConnectedWallet(wallets);

  const handleFund = async(amount) => {
    console.log("handleFund", amount)
    await wallet.fund({
      chain: baseSepolia,
      amount: amount, // ETH
    });
  }

  return { handleFund };
}

export default useDeposit;
