import React from "react";
import "../App.css";
import gameImage from "../bg-game.png";
import useCustomLogin from "../hook/useLogin";
import { useGlobalContext } from "../context";

function Login() {
  const { login, ready } = useCustomLogin();
  const { errorMessage } = useGlobalContext();

  const handleLogin = () => {
    login();
  };

  return ready ? (
    <div className="App">
      <header className="App-header">
        <div className="Game-container" style={{ position: "relative" }}>
          {errorMessage && (
            <div className="login-error-message">
              <p className="">{errorMessage}</p>
            </div>
          )}
          <img src={gameImage} alt="Game Image" className="Game-image" />
          <button onClick={handleLogin} className="Play-button">
            <span className="press-start-2p-regular">Log In</span>
          </button>
        </div>
      </header>
    </div>
  ) : (
    <></>
  );
}

export default Login;
