import React from "react";
import "./App.css";

import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Login from "./pages/Login";
import { publicRoutes } from "./routes/route";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const ProtectedRoute = ({ children }) => {
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      const path = window.btoa(
        window.location.pathname + window.location.search
      );
      return <Navigate to={`/login?next=${path}`} />;
    }
    return children;
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          {publicRoutes.map((route, index) => {
            const Page = route.component;

            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <ProtectedRoute>
                    <Page />
                  </ProtectedRoute>
                }
              />
            );
          })}
          <Route path={"/login"} element={<Login />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
